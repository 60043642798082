<template>
	<div>
		<div class="row no-gutters mt-3">
			<div class="col-12 pb-3 d-flex align-items-center">
				<b-button
					class="mr-2"
					variant="primary"
					@click="goBack">
					<i class="fa fa-arrow-left pr-2" />{{ translate('go_back') }}
				</b-button>
				<h5
					v-if="!loadingInitialInformation"
					class="m-0">
					<b>{{ `${translate('order_id')}: ${orderData.order_id}` }}</b>
				</h5>
			</div>
			<div
				class="col d-flex justify-content-center">
				<div
					class="h2">
					{{ translate('use_another_payment_method') }}
				</div>
			</div>
		</div>
		<b-alert
			v-if="hasValidationErrors"
			class="mt-2"
			show
			variant="danger">
			<h5 class="font-weight-bold">
				<i class="fa fa-exclamation-triangle" /> {{ translate('verify_following_errors') }}:
			</h5>
			<ul class="h6">
				<template v-for="(error, index) in validationErrors">
					<li :key="index">
						{{ error }}
					</li>
				</template>
			</ul>
		</b-alert>
		<div
			:class="['xs'].includes(windowWidth) ? 'mt-2' : 'mt-3'"
			class="row mb-5">
			<div class="col-12 col-md-8">
				<div class="row">
					<div class="col-12">
						<flow-info-group>
							<flow-info-section
								v-if="hasShippingInfo"
								compact
								:title="`${translate('shipping_information')}`"
								hide-controls
								hide-cancel
								override-edit>
								<shipping-overview
									:loading-override="loadingInitialInformation"
									:shipping-method-import="shippingMethod"
									:pickup-at-office="orderData.pickup_at_office"
									:address="orderData.shipping_address" />
							</flow-info-section>
							<flow-info-section
								init-edit
								hide-controls
								:title="`${translate('payment_method')}`"
								active-color="dark"
								hide-cancel
								override-edit
								no-inner-spacing>
								<payment-edit
									ref="paymentEdit"
									:clicked-payment-method="clickedPayment"
									:loading-order-data="loadingInitialInformation"
									:loading-change-payment-method="loadingChangePaymentMethod"
									:change-payment-method-errors="changePaymentMethodErrors"
									:excluded-payment-methods="excludedPaymentMethods"
									:selected-payment-method="newPaymentInfo"
									:cart-total="orderNumericTotal"
									:order-id="+orderId"
									:can-split-with-wallet="canSplitWithWallet"
									@changePaymentMethodSelected="changePaymentMethodSelected"
									@readyState="isPaymentInfoReady = $event" />
							</flow-info-section>
						</flow-info-group>
					</div>
					<div
						class="col-12">
						<div class="row mt-4 pl-4">
							<div class="col">
								<h5>{{ translate('this_orders_items') }}</h5>
							</div>
						</div>
						<div class="row mt-1">
							<div class="col">
								<cart-items
									:loading="systemLoading"
									:cart-items="orderProducts"
									:is-pack="true"
									:qty-import="qty"
									hide-loading
									editing-disabled />
							</div>
						</div>
						<is-loading
							:loading-label="translate('loading')"
							:no-data-label="translate('empty_cart')"
							:loading="systemLoading"
							:has-data="orderProducts.length > 0" />
						<hr class="my-3 w-100">
					</div>
				</div>
			</div>
			<div
				:class="{ 'mt-3': ['xs', 'sm'].includes(windowWidth) }"
				class="col-12 col-md-4">
				<order-summary
					:disable-action="(newPaymentInfo.payment_method.wallet_split && !newPaymentInfo.payment_method.wallet_split_agreement) || systemLoading || !isPaymentInfoReady || (typeof paymentMethodConditions !== 'undefined' ? !paymentMethodConditions.isAvailable : false)"
					:order-loading="loadingInitialInformation"
					:order-totals="orderTotals"
					:order-small-text-totals="orderSmallTextTotals"
					:order-items="orderProducts"
					:order-total="orderTotal"
					:total-volume="totalVolume"
					:exchange="exchange.is_needed ? exchange.total : ''"
					:style="['xs', 'sm'].includes(windowWidth) ? '' : `top: 85px !important;`"
					:class="['xs', 'sm'].includes(windowWidth) ? '' : 'sticky-top'"
					@payClicked="handleContinue" />
			</div>
		</div>
		<b-alert
			v-if="hasValidationErrors && ['xs','sm'].includes(windowWidth)"
			class="mt-2"
			show
			variant="danger">
			<h5 class="font-weight-bold">
				<i class="fa fa-exclamation-triangle" /> {{ translate('verify_following_errors') }}:
			</h5>
			<ul class="h6">
				<template v-for="(error, index) in validationErrors">
					<li :key="index">
						{{ error }}
					</li>
				</template>
			</ul>
		</b-alert>
		<same-address-as-card-statement-modal
			v-model="showSameAddressAsCardStatementModal"
			:country-to-check="country"
			:card-type="selectedCardInfo().card_type"
			:last-digits="selectedCardInfo().last_digits"
			:address="selectedCardInfo().address"
			@confirm="handlePayClicked" />
	</div>
</template>
<script>
import CartItems from '@/components/Cart/CartItems';
import FlowInfoGroup from '@/components/FlowInfo/Group';
import FlowInfoSection from '@/components/FlowInfo/Section';
import IsLoading from '@/components/Loading';
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import { affiliate, distributor } from '@/settings/Roles';
import { WALLET_TYPES } from '@/settings/Wallets';
import {
	Cart, Grids, Profile, Purchase as PurchaseMessages, Validations,
} from '@/translations';
import OrderSummary from './components/Confirmation/OrderSummary';
import PaymentEdit from './components/Payment/Edit';
import ShippingOverview from './components/Shipping/Overview';
import OrderData from './mixins/OrderData';
// import { PRE_CONFIRMATION_NOTE_COUNTRIES } from '@/settings/Country';
import { COUNTRIES_SPLIT_WITH_WALLET_BLACKLIST, EXCLUDED_CHANGE_PAYMENT_RESTRICTION } from '@/settings/Purchase';
import SameAddressAsCardStatementModal from '@/components/SameAddressAsCardStatementModal/index.vue';
import { CREDIT_CARD_FORM_METHODS } from '@/settings/CreditCard';

export default {
	name: 'ChangePaymentMethodConfirmation',
	messages: [Cart, Grids, Profile, PurchaseMessages, Validations],
	components: {
		SameAddressAsCardStatementModal,
		CartItems,
		FlowInfoGroup,
		FlowInfoSection,
		IsLoading,
		OrderSummary,
		PaymentEdit,
		ShippingOverview,
	},
	mixins: [DocumentTitle, OrderData, WindowSizes],
	data() {
		return {
			distributor,
			canLeave: false,
			newPaymentInfo: {},
			validationErrors: [],
			isPaymentInfoReady: false,
			clickedPayment: '',
			showSameAddressAsCardStatementModal: false,
			paymentMethodConditions: {},
			countriesNotSplitWithWallet: COUNTRIES_SPLIT_WITH_WALLET_BLACKLIST,
		};
	},
	computed: {
		isCardPaymentMethod() {
			return CREDIT_CARD_FORM_METHODS.includes(this.newPaymentInfo.payment_method.name);
		},
		excludedPaymentMethods() {
			const excluded = EXCLUDED_CHANGE_PAYMENT_RESTRICTION.includes(this.paymentMethod) ? [] : [this.paymentMethod];
			return excluded;
		},
		hasValidationErrors() {
			return !!this.validationErrors.length;
		},
		hasShippingInfo() {
			try {
				return Object.keys(this.orderData.shipping_address).length > 0;
			} catch (e) {
				return false;
			}
		},
		canSplitWithWallet() {
			return this.$user.auth() && [distributor, affiliate].includes(this.$user.details().type)
				&& !this.countriesNotSplitWithWallet.includes(this.$user.details().country.iso_code_2);
		},
	},
	watch: {
		cartNumericTotal(amountToPay) {
			if (typeof this.paymentMethodConditions !== 'undefined') {
				const min = this.paymentMethodConditions.min || null;
				const max = this.paymentMethodConditions.max || null;
				const subtotalMin = min !== null ? amountToPay >= min : true;
				const subtotalMax = max !== null ? amountToPay <= max : true;
				if (!subtotalMin || !subtotalMax) {
					this.editStep('PurchasePayment');
				}
			}
		},
	},
	created() {
		this.initializeNewPaymentInfo();
	},
	async mounted() {
		this.getOrderInfo();
	},
	methods: {
		selectedCardInfo() {
			try {
				if (this.newPaymentInfo.payment_method.card_id) {
					// eslint-disable-next-line eqeqeq
					return this.$refs.paymentEdit.$refs.creditCardPayment.creditCardsData.find((card) => card.id == this.newPaymentInfo.payment_method.card_id).attributes;
				}
			} catch (e) {
				return {};
			}
			return {};
		},
		handleContinue() {
			if (this.isCardPaymentMethod) {
				this.showSameAddressAsCardStatementModal = true;
			} else {
				this.handlePayClicked();
			}
		},
		initializeNewPaymentInfo() {
			this.$set(this.newPaymentInfo, 'billing', {});
			this.$set(this.newPaymentInfo.billing, 'address_id', 0);

			this.$set(this.newPaymentInfo, 'payment_method', {});
			this.$set(this.newPaymentInfo.payment_method, 'name', '');
			this.$set(this.newPaymentInfo.payment_method, 'card_id', 0);
			this.$set(this.newPaymentInfo.payment_method, 'wallet_password', '');
			this.$set(this.newPaymentInfo.payment_method, 'wallet_type', WALLET_TYPES.commission);
			this.$set(this.newPaymentInfo.payment_method, 'wallet_split', false);
			this.$set(this.newPaymentInfo.payment_method, 'wallet_split_amount', '');
			this.$set(this.newPaymentInfo.payment_method, 'wallet_split_agreement', false);
			// eslint-disable-next-line no-underscore-dangle
			this.$set(this.newPaymentInfo.payment_method, 'sift_session_id', window._sift_session_id);
		},
		handlePayClicked() {
			// if (PRE_CONFIRMATION_NOTE_COUNTRIES.includes(this.country)) {
			if (['CR'].includes(this.country)) {
				const alertConfig = {
					icon: 'info',
					title: this.translate('note_title'),
					text: this.translate(`${this.country.toLowerCase()}_note_text`),
					showCancelButton: true,
					confirmButtonText: this.translate('confirm_note_i_agree'),
					reverseButtons: true,
				};
				this.alert.preConfirm(alertConfig, () => {
					this.handlePayClickedValidated();
				}, () => {});
			} else {
				this.handlePayClickedValidated();
			}
		},
		handlePayClickedValidated() {
			const handler = () => {
				this.changePaymentMethod(this.newPaymentInfo, {
					serverErrorHandler: this.goBack,
					invalidRequestHandler: this.goBack,
					validationErrorsHandler: this.handleValidationErrors,
				}).then(() => {
					this.$user.init();
				});
			};

			this.validationErrors = [];
			const trans = {
				title: this.translate('important').toUpperCase(),
				text: this.translate('payment_method_change_notice'),
			};
			const options = {
				confirmButtonText: this.translate('pay'),
				cancelButtonText: this.translate('cancel'),
			};
			this.alert.confirmation(trans.title, trans.text, options).then(() => {
				handler();
			}).catch(() => {});
		},
		goBack() {
			this.canLeave = true;
			this.$router.go(-1);
		},
		handleValidationErrors(errors) {
			this.validationErrors = errors;
		},
		changePaymentMethodSelected(selected, conditions) {
			if (selected !== 0) this.clickedPayment = selected;
			if (conditions) this.paymentMethodConditions = conditions;
		},
	},
	beforeRouteLeave(to, from, next) {
		if (to.name.includes('ChangePaymentMethod')) {
			next();
			const alert = new this.$Alert();
			alert.close();
		} else if (['Login', 'Store', 'OrdersAll', 'OrdersDetails'].includes(to.name)) {
			if (this.canLeave) {
				next();
				const alert = new this.$Alert();
				alert.close();
			} else {
				next();
			}
		} else if (this.canLeave) {
			next();
		}
	},
};
</script>
